import { useEffect } from "react";

const target = new URL("/dashboard", window.location.origin);

export default function Index(): null {
  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const redirect = search.get("url_logged");
    if (redirect) {
      const target = new URL(redirect, window.location.origin);
      window.location.assign(target.toString());
      return;
    }

    window.location.assign(target.toString());
  }, []);

  return null;
}
