import { useContext, useMemo } from "react";

import amplifyOutputs from "@/backend-data-amplify-config.json";
import type { Schema } from "@/backend-data-schema";
import { type CustomHeaders } from "@aws-amplify/data-schema/runtime";

import type { AmplifyOutputs } from "aws-amplify/adapter-core";
import { generateClient } from "aws-amplify/data";

import { AuthContext } from "../auth";

let cachedAmplifyOutputs: AmplifyOutputs | undefined = undefined;
/**
 * @returns The Amplify outputs from the backend.
 */
export async function getAmplifyOutputs(): Promise<AmplifyOutputs | undefined> {
  if (cachedAmplifyOutputs !== undefined) {
    return cachedAmplifyOutputs;
  }
  if (Object.keys(amplifyOutputs).length > 0) {
    cachedAmplifyOutputs = amplifyOutputs;
    return amplifyOutputs;
  }
  if (import.meta.env.VITE_AMPLIFY_OUTPUTS_URL) {
    try {
      const response = await fetch(import.meta.env.VITE_AMPLIFY_OUTPUTS_URL);
      const object = await response.json();
      if (Object.keys(object).length > 0) {
        cachedAmplifyOutputs = object;
        return object;
      }
    } catch {
      //TODO: once backend is properly setup this should be removed as we want to be notified of any errors fetching the config
      // but for now, we can just return undefined
      return undefined;
    }
  }
  return undefined;
}

export type AmplifyBackendClient = ReturnType<typeof generateClient<Schema>>;

export function useAmplifyBackendClient({
  useApiKey = true,
  isPublic = false,
}: {
  useApiKey?: boolean;
  isPublic?: boolean;
} = {}): AmplifyBackendClient {
  // use the context directly instead of useAuth because we could be in non authentified webapps, without a provider
  const authContext = useContext(AuthContext);
  const bearer = authContext?.bearer;

  const amplifyBackendClient = useMemo(() => {
    return generateClient<Schema>({
      headers: async () => {
        let result: CustomHeaders = {};
        if (bearer) {
          result = { Authorization: bearer.replace(/^Bearer jwt:/, "") };
        }
        if (useApiKey) {
          const amplifyOutputs = await getAmplifyOutputs();
          if (amplifyOutputs?.data?.api_key) {
            result["X-Api-Key"] = amplifyOutputs.data.api_key;
          }
        }
        return result;
      },
      authMode: isPublic ? "apiKey" : "userPool",
    });
  }, [bearer, useApiKey, isPublic]);

  return amplifyBackendClient;
}
