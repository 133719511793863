import { type ReactNode, type JSX, createContext, useContext } from "react";
import { cloneElement } from "react";

import {
  cx,
  sva,
  type RecipeVariantProps,
} from "@mobsuccess-devops/styled-system/css";
import { vstack } from "@mobsuccess-devops/styled-system/patterns";

import { composition } from "../../../features/utils/composition";
import { warnOnce } from "../../../features/utils/warn-once";
import { Typo } from "../../Typo";

import { Dot } from "./Dot";
import { Ellipsis } from "./Ellipsis";

const LoaderContext =
  createContext<RecipeVariantProps<typeof styles>>(undefined);

type DescriptionProps = {
  children: ReactNode;
};

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
function Description({ children }: DescriptionProps): JSX.Element {
  const config = useContext(LoaderContext);
  if (!config) {
    throw new Error("Description must be used inside a Loader component");
  }
  const classes = styles(config);

  return <Typo.Body className={classes.description}>{children}</Typo.Body>;
}

type BlockProps = RecipeVariantProps<typeof styles> & {
  className?: string;
  children: ReactNode;
};

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
function Block({
  size,
  className,
  variant = "light",
  palette = "interface",
  children,
  shown,
}: BlockProps): JSX.Element | null {
  const classes = styles({ size, variant, shown, palette });

  const { spinner, description } = composition.split(children, {
    spinner: composition.single([Dot, Ellipsis]),
    description: composition.single(Description),
  });

  const config = {
    size,
    variant,
  };

  if (spinner.props.size || spinner.props.color || spinner.props.shown) {
    warnOnce(
      "Loader spinner is within a block, the size, color and shown props on the spinner will be ignored",
    );
  }

  return (
    <LoaderContext.Provider value={config}>
      <div className={cx("block-loader", classes.block, className)}>
        {cloneElement(spinner, {
          size,
          color:
            variant.startsWith("light") || variant === "transparent"
              ? "dark"
              : "light",
          shown,
        })}
        {description}
      </div>
    </LoaderContext.Provider>
  );
}

export const Loader = {
  Block,
  Description,
  Dot,
  Ellipsis,
};

const styles = sva({
  slots: ["block", "description"],
  base: {
    block: {
      zIndex: "overlay",
      top: 0,
      left: 0,
      size: "full",
      center: true,
      ...vstack.raw(),
      position: "absolute",
      background:
        "color-mix(in srgb, var(--background-color, white) 95%, transparent)",
    },
  },
  variants: {
    size: {
      sm: {
        block: {
          gap: "sm",
        },
        description: {
          textStyle: "muted",
          textAlign: "center",
        },
      },
      md: {
        block: {
          gap: "md",
        },
        description: {
          textStyle: "body",
          textAlign: "center",
        },
      },
      lg: {
        block: {
          gap: "sm",
        },
        description: {
          textStyle: "lg",
          textAlign: "center",
        },
      },
    },
    variant: {
      transparent: {
        block: {
          "--background-color": "transparent",
        },
        description: {
          color: "colorPalette",
        },
      },
      lighter: {
        block: {
          "--background-color": "white",
        },
        description: {
          color: "colorPalette",
        },
      },
      light: {
        block: {
          "--background-color": "token(colors.colorPalette.100)",
        },
        description: {
          color: "interface",
        },
      },
      dark: {
        block: {
          "--background-color": "token(colors.colorPalette)",
        },
        description: {
          color: "white",
        },
      },
      darker: {
        block: {
          "--background-color": "token(colors.colorPalette.600)",
        },
        description: {
          color: "white",
        },
      },
    },
    palette: {
      primary: {
        block: {
          colorPalette: "primary",
        },
      },
      interface: {
        block: {
          colorPalette: "interface",
        },
      },
      danger: {
        block: {
          colorPalette: "danger",
        },
      },
    },
    shown: {
      false: {
        block: {
          display: "none",
        },
      },
    },
  },
  compoundVariants: [
    {
      variant: "dark",
      palette: "primary",
      css: {
        block: {
          "--background-color": "token(colors.primary)",
        },
      },
    },
    {
      variant: "dark",
      palette: "interface",
      css: {
        block: {
          "--background-color": "token(colors.interface)",
        },
      },
    },
    {
      variant: "dark",
      palette: "danger",
      css: {
        block: {
          "--background-color": "token(colors.danger)",
        },
      },
    },
    {
      variant: "darker",
      palette: "primary",
      css: {
        block: {
          "--background-color": "token(colors.primary.600)",
        },
      },
    },
    {
      variant: "darker",
      palette: "interface",
      css: {
        block: {
          "--background-color": "token(colors.interface.600)",
        },
      },
    },
    {
      variant: "darker",
      palette: "danger",
      css: {
        block: {
          "--background-color": "token(colors.danger.600)",
        },
      },
    },
  ],
  defaultVariants: {
    size: "md",
    variant: "light",
    shown: true,
  },
});
