import { createContext, useContext } from "react";

import type { I18n, Messages } from "@lingui/core";
import { compileMessage } from "@lingui/message-utils/compileMessage";
import { useLingui } from "@lingui/react";
import { captureException } from "@sentry/react";

import { CookieStorage } from "aws-amplify/utils";
import { basename } from "path";

export const ENV_LOCALES: string[] = (import.meta.env.VITE_LANGUAGES ?? "en,fr")
  .split(",")
  .map((locale: string) => locale.trim())
  .filter(Boolean);

const sharedLocales = import.meta.glob<LocaleModule>(
  "../../locales/!(*.d).ts",
  {
    eager: true,
  },
);

const fallbackLocale = basename(Object.keys(sharedLocales)[0], ".ts");

export type LocaleModule = {
  messages: Messages;
};

export type LanguageContextType = {
  language: string;
  languagesMap: Map<string, string>;
  availableLanguages: string[];
  onLanguageChange: (language: string) => void;
};

export const LanguageContext = createContext<LanguageContextType | null>(null);

export function useLanguageContext(): LanguageContextType {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("LanguageContext is not defined");
  }
  return context;
}

export function useI18n(): I18n {
  const { i18n } = useLingui();
  return i18n;
}

export function buildLocales(
  webappsLocales: Record<string, LocaleModule>,
): Map<string, LocaleModule["messages"]> {
  const allLocales = [
    ...Object.entries(sharedLocales),
    ...Object.entries(webappsLocales),
  ];
  return allLocales.reduce((locales, [key, module]) => {
    const locale = basename(key, ".ts");
    if (!locales.has(locale)) {
      locales.set(locale, {});
    }
    const loaded = locales.get(locale);
    locales.set(locale, {
      ...loaded,
      ...module.messages,
    });
    return locales;
  }, new Map<string, LocaleModule["messages"]>());
}

const crowdinDistributionHash = import.meta.env.VITE_CROWDIN_DISTRIBUTION_HASH;
const failedDistantLocales: Set<string> = new Set();
const distantLocales: Map<string, LocaleModule["messages"]> = new Map();
let crowdinManifestTimestamp: number | undefined = undefined;
const crowdinLanguageMapping: Record<string, string> = {
  en: "en_US",
  fr: "fr_FRO",
};

async function fetchCrowdinDistributionManifest() {
  if (typeof crowdinManifestTimestamp === "number") {
    return crowdinManifestTimestamp;
  }
  if (!crowdinDistributionHash) {
    return undefined;
  }
  try {
    const res = await fetch(
      `https://distributions.crowdin.net/${crowdinDistributionHash}/manifest.json`,
    );
    const manifest = await res.json();
    if (typeof manifest.timestamp === "number") {
      crowdinManifestTimestamp = manifest.timestamp;
      return manifest.timestamp;
    }
  } catch (e) {
    captureException(e);
    return undefined;
  }
}

async function fetchDistantLocale(
  projectId: string,
  locale: string,
): Promise<Messages | undefined> {
  const cacheKey = `${projectId}_${locale}`;
  if (distantLocales.has(cacheKey)) {
    return distantLocales.get(cacheKey);
  }
  if (failedDistantLocales.has(cacheKey)) {
    return undefined;
  }
  const crowdinLocale = crowdinLanguageMapping[locale] ?? locale;
  try {
    const timestamp = await fetchCrowdinDistributionManifest();
    const res = await fetch(
      `https://distributions.crowdin.net/${crowdinDistributionHash}/content/${projectId}_${crowdinLocale}.json?timestamp=${timestamp}`,
    );
    const messages = await res.json();
    const compiledMessages = compileLinguiDynamicMessages(messages);
    distantLocales.set(cacheKey, compiledMessages);
    return compiledMessages;
  } catch (e) {
    // we don't want to block the app if the distant locale is not available, but we want to know about it
    captureException(e);
    failedDistantLocales.add(cacheKey);
    return undefined;
  }
}

export async function activate(
  i18n: I18n,
  locales: Map<string, LocaleModule["messages"]>,
): Promise<void> {
  const resolvedLocale = await resolveAppLocale(null);

  const distantReactSharedLocale = await fetchDistantLocale(
    "react-shared",
    resolvedLocale,
  );
  let distantLocale: Messages | undefined = undefined;
  const projectId = import.meta.env.VITE_MOON_PROJECT_ID;
  if (projectId) {
    distantLocale = await fetchDistantLocale(projectId, resolvedLocale);
  }
  const locale = locales.get(resolvedLocale);
  if (!distantLocale && !locale) {
    throw new Error(`Language ${resolvedLocale} is not supported`);
  }
  const catalog: Messages = {
    ...locale,
    ...distantReactSharedLocale,
    ...distantLocale,
  };

  i18n.load(resolvedLocale, catalog);
  i18n.activate(resolvedLocale);
}

export async function changeLocale(
  locales: Map<string, LocaleModule["messages"]>,
  locale: string,
): Promise<void> {
  const resolvedLocale = await resolveAppLocale(locale);
  if (!locales.has(resolvedLocale)) {
    throw new Error(`Language ${resolvedLocale} is not supported`);
  }
  const cookies = new CookieStorage({
    domain:
      import.meta.env.VITE_IS_DEV === "true" ? undefined : ".mobsuccess.com",
  });
  cookies.setItem("ms_lang", resolvedLocale);
  window.location.reload();
}

async function resolveAppLocale(
  locale: string | null = null,
  supportedLocales: string[] = ENV_LOCALES,
): Promise<string> {
  if (isSupportedLocale(locale, supportedLocales)) {
    return locale;
  }

  const cookieLocale = await getCookieLocale(supportedLocales);
  if (cookieLocale) {
    return cookieLocale;
  }

  const browserLanguage = getBrowserLanguage(supportedLocales);
  if (browserLanguage) {
    return browserLanguage;
  }

  return getDefaultLocale(supportedLocales, fallbackLocale);
}

function isSupportedLocale(
  locale: string | null,
  supportedLocales: string[],
): locale is string {
  return Boolean(locale && supportedLocales.includes(locale));
}

async function getCookieLocale(
  supportedLocales: string[],
): Promise<string | null> {
  try {
    const cookies = new CookieStorage();
    const locale = await cookies.getItem("ms_lang");
    return isSupportedLocale(locale, supportedLocales) ? locale : null;
  } catch {
    return null;
  }
}

function getBrowserLanguage(supportedLocales: string[]): string | null {
  try {
    const [language] = navigator.language.split("-");
    return isSupportedLocale(language, supportedLocales) ? language : null;
  } catch {
    return null;
  }
}

function getDefaultLocale(
  supportedLocales: string[],
  fallback: string,
): string {
  const defaultLocale = supportedLocales?.[0];
  return isSupportedLocale(defaultLocale, supportedLocales)
    ? defaultLocale
    : fallback;
}

export type InputMessages = Record<string, string>;

export function compileLinguiDynamicMessages(
  inputMessages: InputMessages,
): Messages {
  return Object.fromEntries(
    Object.entries(inputMessages).map(([key, value]) => {
      return [key, compileMessage(value)];
    }),
  );
}
