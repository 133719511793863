import { type ReactNode, useMemo, type JSX } from "react";

import { t } from "@lingui/macro";
import { LocalesContext } from "@mobsuccess-devops/react-ui/Locales";

import { useI18n } from "./i18n";

type ReactUILocalesProps = {
  children: ReactNode;
};

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
export function ReactUILocales({ children }: ReactUILocalesProps): JSX.Element {
  const { locale } = useI18n();
  const dictionay = useMemo(
    () => ({
      locale: locale,
      general: {
        save: t`#src.public.react-ui.general.save`,
        reset: t`#src.public.react-ui.general.reset`,
      },
      select: {
        selectAll: t`#src.public.react-ui.select.select-all`,
        removeAll: t`#src.public.react-ui.select.remove-all`,
      },
      accountDropdown: {
        searchPlaceholder: t`#src.public.react-ui.account-dropdown.search-placeholder`,
      },
      headerAccountPicker: {
        searchPlaceholder: t`#src.public.react-ui.header-account-picker.search-placeholder`,
      },
      pagination: {
        pageSizeLabel: t`#src.public.react-ui.pagination.page-size-label`,
        pageSizeOptions: (size: number) =>
          t`#src.public.react-ui.pagination.page-size-options(${size})`,
      },
      colorPicker: {
        savedSectionLabel: t`#src.public.react-ui.color-picker.saved-section-label`,
      },
      filters: {
        searchPlaceholder: t`#src.public.react-ui.filters.search-placeholder`,
      },
      table: {
        arrangeColumns: t`#src.public.react-ui.table.arrange-columns`,
      },
      dateRangePicker: {
        reset: t`#src.public.react-ui.date-range-picker.reset`,
        from: t`#src.public.react-ui.date-range-picker.from`,
        to: t`#src.public.react-ui.date-range-picker.to`,
        rangePlaceholder: t`#src.public.react-ui.date-range-picker.range-placeholder`,
        thisWeek: t`#src.public.react-ui.date-range-picker.this-week`,
        lastWeek: t`#src.public.react-ui.date-range-picker.last-week`,
        thisMonth: t`#src.public.react-ui.date-range-picker.this-month`,
        lastMonth: t`#src.public.react-ui.date-range-picker.last-month`,
        thisQuarter: t`#src.public.react-ui.date-range-picker.this-quarter`,
        lastQuarter: t`#src.public.react-ui.date-range-picker.last-quarter`,
        thisYear: t`#src.public.react-ui.date-range-picker.this-year`,
        lastYear: t`#src.public.react-ui.date-range-picker.last-year`,
        last3Days: t`#src.public.react-ui.date-range-picker.last-days(${3})`,
        last7Days: t`#src.public.react-ui.date-range-picker.last-days(${7})`,
        last14Days: t`#src.public.react-ui.date-range-picker.last-days(${14})`,
        last30Days: t`#src.public.react-ui.date-range-picker.last-days(${30})`,
        last90Days: t`#src.public.react-ui.date-range-picker.last-days(${90})`,
        today: t`#src.public.react-ui.date-range-picker.today`,
        yesterday: t`#src.public.react-ui.date-range-picker.yesterday`,
      },
      sidebar: {
        termsAndConditions: t`#src.public.react-ui.sidebar.terms-and-conditions`,
        languageSelectorDialog: {
          title: t`#src.public.react-ui.sidebar.language-selector-dialog.title`,
          description: t`#src.public.react-ui.sidebar.language-selector-dialog.description`,
          cancel: t`#src.public.react-ui.sidebar.language-selector-dialog.cancel`,
          confirm: t`#src.public.react-ui.sidebar.language-selector-dialog.confirm`,
        },
      },
      moby: {
        title: t`#src.public.react-ui.moby.title`,
        send: t`#src.public.react-ui.moby.send`,
        askAQuestion: t`#src.public.react-ui.moby.askAQuestion`,
        resetRequest: t`#src.public.react-ui.moby.resetRequest`,
        resetRequestDescription: t`#src.public.react-ui.moby.resetRequestDescription`,
        cancel: t`#src.public.react-ui.moby.cancel`,
        reset: t`#src.public.react-ui.moby.reset`,
        happyWithThisConv: t`#src.public.react-ui.moby.happyWithThisConv`,
      },
    }),
    [locale],
  );

  return (
    <LocalesContext.Provider value={dictionay}>
      {children}
    </LocalesContext.Provider>
  );
}
