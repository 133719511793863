import { createContext, useContext } from "react";

import { t } from "@lingui/macro";
import { useToasters } from "@mobsuccess-devops/react-ui/Toaster";
import { useMutation } from "@tanstack/react-query";

import { Amplify } from "aws-amplify";
import { fetchAuthSession, signOut } from "aws-amplify/auth";
import { parseAmplifyConfig } from "aws-amplify/utils";
import { AxiosError } from "axios";
import { jwtDecode } from "jwt-decode";

import { getAmplifyOutputs } from "../amplify-backend";
import { axiosPost } from "../fetch/query";

export type CognitoUserSession = Awaited<ReturnType<typeof fetchAuthSession>>;

export enum AuthActionEnum {
  SignIn = "sign-in",
  SignOut = "sign-out",
  ResetPassword = "reset-password",
  ForgotPassword = "forgot-password",
}

export function isAuthAction(value?: string | null): value is AuthActionEnum {
  const actions: Array<string> = Object.values(AuthActionEnum);
  return value ? actions.includes(value) : false;
}

export type PatSession = {
  pat: string;
  profile: string;
};

export type AuthContextType = {
  session: CognitoUserSession | PatSession | null;
  resetPassword(_: {
    email: string;
    code: string;
    password: string;
  }): Promise<void>;
  jwt: string | null;
  pat: string | null;
  bearer: string | null;
  isLoggedIn: boolean;
  signOut(_?: { redirect?: string }): Promise<void>;
  forgotPassword(_: { email: string }): Promise<void>;
  signIn(email: string, password: string): Promise<void>;
  signInWithPat({
    pat,
    profile,
  }: {
    pat: string;
    profile: string;
  }): Promise<void>;
  googleSignIn(_: { credential: string }): Promise<void>;
};

export const AuthContext = createContext<AuthContextType | null>(null);

export function useAuth(): AuthContextType {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

export async function configure(): Promise<void> {
  const amplifyOutputs = await getAmplifyOutputs();
  Amplify.configure({
    ...parseAmplifyConfig(amplifyOutputs ?? {}),
  });
}

enum CognitoStorageMagics {
  LastAuthUser = "LastAuthUser",
  IdToken = "idToken",
  RefreshToken = "refreshToken",
  AccessToken = "accessToken",
}

export async function synchPhpSessionWithCognitoStorage(
  userPoolsWebClientId: string,
): Promise<void> {
  if (!window._msAuthSession) {
    return;
  }
  const cognitoStorage: Array<[string, string | null]> = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key?.startsWith("CognitoIdentityServiceProvider")) {
      cognitoStorage.push([key, localStorage.getItem(key)]);
    }
  }
  const setCognitoSessionItem = (itemKey: Array<string>, value: string) => {
    const key = [
      "CognitoIdentityServiceProvider",
      userPoolsWebClientId,
      ...itemKey,
    ].join(".");
    localStorage.setItem(key, value);
  };
  const session = window._msAuthSession;
  const oldEmail = cognitoStorage.find(([key]) =>
    key?.endsWith(CognitoStorageMagics.LastAuthUser),
  );
  const payload = jwtDecode<{ "cognito:username": string }>(session.idToken);

  if (
    oldEmail &&
    oldEmail[1] !== session.email &&
    oldEmail[1] !== payload["cognito:username"]
  ) {
    await signOut();
  } else {
    cognitoStorage.forEach(([key]) => localStorage.removeItem(key));
  }

  setCognitoSessionItem(
    [CognitoStorageMagics.LastAuthUser],
    payload["cognito:username"],
  );
  setCognitoSessionItem(
    [payload["cognito:username"], CognitoStorageMagics.IdToken],
    session.idToken,
  );
  setCognitoSessionItem(
    [payload["cognito:username"], CognitoStorageMagics.RefreshToken],
    session.refreshToken,
  );
  setCognitoSessionItem(
    [payload["cognito:username"], CognitoStorageMagics.AccessToken],
    session.accessToken,
  );
  delete window._msAuthSession;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useForgotPassword() {
  const { toast } = useToasters();

  return useMutation({
    mutationKey: [AuthActionEnum.ForgotPassword],
    async mutationFn(options: { email: string }) {
      await axiosPost({
        entity: "auth",
        verb: "sendresetpassword",
        token: null,
        params: {
          email: options.email,
        },
      });
    },
    onError(error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 422) {
          const data = error.response.data;
          let email = "contact@mobsuccess.com";
          if ("error" in data && "message" in data.error) {
            email = String(data.error.message).replace(/^.+NPAI : (.+)$/, "$1");
          }
          toast.error({
            title: t`#src.public.auth.forgot-password-error.title`,
            description: t`#src.public.auth.forgot-password-error.description-npai`,
            actions: [
              {
                id: "contact",
                label: t`#src.public.auth.forgot-password-error.button-text`,
                onClick: () => window.open(`mailto:${email}`, "_blank"),
                variant: "linear",
                palette: "danger",
              },
            ],
          });
        }
      }
      toast.error({
        title: t`#src.public.auth.forgot-password-error.title`,
        description: t`#src.public.auth.forgot-password-error.description`,
      });
    },
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useResetPassword() {
  const { toast } = useToasters();
  return useMutation({
    mutationKey: [AuthActionEnum.ResetPassword],
    async mutationFn(options: {
      email: string;
      code: string;
      password: string;
    }) {
      await axiosPost({
        entity: "auth",
        verb: "resetpassword",
        token: null,
        params: {
          email: options.email,
          code: options.code,
          password: options.password,
        },
      });
    },
    onSuccess() {
      toast.success({
        title: t`#src.public.auth.reset-password-success.title`,
        description: t`#src.public.auth.reset-password-success.description`,
      });
    },
    onError() {
      toast.error({
        title: t`#src.public.auth.reset-password-error.title`,
        description: t`#src.public.auth.reset-password-error.description`,
      });
    },
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useSignout({
  postSignout,
}: {
  postSignout?: (_?: { redirect?: string }) => Promise<void> | void;
} = {}) {
  const { toast } = useToasters();
  return useMutation({
    mutationKey: [AuthActionEnum.SignOut],
    async mutationFn(options?: { redirect?: string }) {
      try {
        // eslint-disable-next-line @mobsuccess-devops/mobsuccess/member-expressions
        const session = await fetchAuthSession().catch(() => null);
        if (session?.tokens) {
          window._msAuthBroadcastChannel?.postMessage("signout");
        }
        await axiosPost({
          entity: "auth",
          verb: "signout",
          token: null,
        });
        await signOut();
      } finally {
        if (postSignout) {
          await postSignout(options);
        }
      }
    },
    onError() {
      toast.error({
        title: t`#src.public.auth.sign-out-error.title`,
        description: t`#src.public.auth.sign-out-error.description`,
      });
    },
  });
}
