import {
  type ReactNode,
  type JSX,
  createContext,
  useContext,
  Fragment,
  Children,
} from "react";

import { css } from "@mobsuccess-devops/styled-system/css";
import { token } from "@mobsuccess-devops/styled-system/tokens";

import { createPortal } from "react-dom";

const zFactorTargetCss = css({
  position: "fixed",
  insetPosition: "0",
  pointerEvents: "none",
  zIndex: "calc(var(--zIndex) + 1)",
  "& > *": {
    pointerEvents: "auto",
  },
});

const factors = {
  default: document.body,
  toolbar: createZFactorTarget("toolbar", token("zIndex.toolbar")),
  sidebar: createZFactorTarget("sidebar", token("zIndex.sidebar")),
  dialog: createZFactorTarget("dialog", token("zIndex.modal")),
};

const ZFactorContext = createContext<keyof typeof factors>("default");

type ZFactorPortalProps = {
  children: ReactNode;
};

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
function ZFactorPortal({ children }: ZFactorPortalProps): JSX.Element {
  const factor = useContext(ZFactorContext);

  return (
    <Fragment>
      {Children.map(children, (child) => createPortal(child, factors[factor]))}
    </Fragment>
  );
}

type ZFactorPortalRootLevelOnlyProps = ZFactorPortalProps;

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
function ZFactorPortalRootLevelOnly({
  children,
}: ZFactorPortalRootLevelOnlyProps): JSX.Element {
  const factor = useContext(ZFactorContext);
  // If the current context factor is not "default", it indicates that the content
  // is already within a portal. In this case, we simply return the children as is,
  // preventing the creation of an additional portal. This avoids unexcpected behavior.
  if (factor !== "default") {
    return <Fragment>{children}</Fragment>;
  }
  return <ZFactorPortal>{children}</ZFactorPortal>;
}

export const ZFactor = {
  Provider: ZFactorContext.Provider,
  Portal: ZFactorPortal,
  PortalRootLevelOnly: ZFactorPortalRootLevelOnly,
};

function createZFactorTarget(id: string, zIndexValue: string) {
  const target = document.createElement("div");
  target.id = id;
  target.classList.add(...zFactorTargetCss.split(" "));
  target.style.setProperty("--zIndex", zIndexValue);
  document.body.appendChild(target);

  return target;
}
